import { useAuth0 } from '@auth0/auth0-react'
import { createTheme, ThemeProvider } from '@mui/material'
import { useState, useEffect } from 'react'
import { DesktopView } from './pages/DesktopView'
import { Loading } from './pages/Loading'

import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { fetchWithToken, getChannels, getNonce, getSelf } from './utils/utils'
import { Self, TelegramChannel } from './types/ChannelTypes'
import { AppContext } from './AppContext'

const chakraTheme = extendTheme({
  fonts: {
    body: '\'inter\' sans-serif',
    heading: '\'inter\' sans-serif',
    mono: '\'inter\' sans-serif',
    inter: '\'inter\' sans-serif',
    roboto: '\'roboto\''
  }
})

// MaterialUI Themeing - to remove
const theme = createTheme({
  palette: {
    primary: {
      main: '#5D5AFF'
    }
  }
})

export const App = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0()
  const [finishLoadingAndAuthenticated, setFinishLoading] = useState(false)
  const [accessToken, setAccessToken] = useState('')
  const [channels, setChannels] = useState<TelegramChannel[]>([])
  const [nonce, setNonce] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  // Utility function to obtain access token
  // Tries to obtain it silently, if fails, then obtains it with consent popup
  const getAccessToken = async (): Promise<string> => {
    const audience = process.env.REACT_APP_AUTH0_AUDIENCE || ''

    try {
      const accessToken = await getAccessTokenSilently({
        audience
      })
      return accessToken
    } catch (_error) {
      const isDev = process.env.IS_LOCAL_MACHINE || false
      if (isDev) {
        const accessToken = await getAccessTokenWithPopup({
          audience
        })
        return accessToken
      } else {
        loginWithRedirect() // Redirect to auth0 login
        return ''
      }
    }
  }

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        if (!isAuthenticated) {
          loginWithRedirect() // Redirect to auth0 login
        } else {
          getAccessToken().then((accessTokenResult) => {
            setAccessToken(accessTokenResult)
            setFinishLoading(true)
            Promise.all([
              getNonce(accessTokenResult).then(nonce => setNonce(nonce)),
              getChannels(accessTokenResult).then(channels => setChannels(channels)),
              getSelf(accessTokenResult).then((self) => setUserEmail(self.email))
            ]).then(() => {
              setFinishLoading(true)
              setInterval(() => {
                getChannels(accessTokenResult).then(channels => setChannels(channels))
              }, 20000) // prefetch every 20 seconds
            })
          })
        }
      }, 10) // delay before checking authenticated to avoid race condition (race condition observed in real life testing)
    }
  }, [isLoading, isAuthenticated])

  return (
    finishLoadingAndAuthenticated
      ? (
        <AppContext.Provider value={{ accessToken, channels, nonce, userEmail, setChannels, setNonce }}>
          <ThemeProvider theme={theme}>
            <ChakraProvider theme={chakraTheme}>
              <DesktopView />
            </ChakraProvider>
          </ThemeProvider>
        </AppContext.Provider>
      )
      : <Loading loadingText={'Connecting to Braidd...'} />
  )
}
