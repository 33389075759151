import { Box, Text, OrderedList, ListItem } from '@chakra-ui/react'
import { BackToDashboard } from './components/BackToDashboard'

export const AddAdminRights = () => {
  return (
    <Box mx="auto" width={{ base: 'none', lg: 'max-content' }} maxWidth={{ base: '30rem', lg: '60rem' }} px={{ base: '1rem', lg: '8rem' }}>
      <BackToDashboard />
      <Text
        fontFamily="inter"
        fontWeight="700"
        color="#1E293B"
        fontSize={{ base: '1.5rem', lg: '1.875rem' }}>
            Granting admin rights to Braidd 🔑 </Text>
      <Text
        fontFamily="inter"
        fontWeight="400"
        color="#475569"
      >Before Braidd can help to manage your groups, you&apos;ll need to grant admin rights! Follow these steps:
      </Text>

      <Text mt="2rem" mb="0.25rem" fontFamily="inter" fontWeight="600" color="#1E293B">
        Instructions
      </Text>
      <OrderedList pl="1rem" fontFamily="inter" fontWeight="400" color="#475569" fontSize="0.875rem" mb="0.5rem" spacing="0.25rem">
        <ListItem>
          Inside the group, click on the group&apos;s title at the top to view the group&apos;s information
        </ListItem>
        <ListItem>
          Click on &ldquo;Edit&rdquo; at the top right
        </ListItem>
        <ListItem>
          Click &ldquo;Administrators&rdquo; and then &ldquo;Add Admin&rdquo;
        </ListItem>
        <ListItem>
          Select Braidd from the list of group members
        </ListItem>
        <ListItem>
          Grant all the admin rights to Braidd
        </ListItem>
      </OrderedList>
    </Box>
  )
}
