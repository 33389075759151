import { Box, BoxProps, IconButton } from '@chakra-ui/react'
import { FaTelegram } from 'react-icons/fa'
import { IoIosHelpCircle } from 'react-icons/io'
import { RiDashboardLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import { CategoryText } from './CategoryText'
import { NavbarEntry } from './NavbarEntry'
import { BraiddLogoWhite } from '../../assets/logo/BraiddLogoWhite'

type SidebarProps = BoxProps
export const Sidebar = (props: SidebarProps) => {
  const navigate = useNavigate()
  return (
    <Box
      bgColor="#1E293B"
      width="15rem"
      p="1rem"
      pt="1.5rem"
      minHeight="100vh"
      {...props}
    >
      <IconButton
        aria-label="logo"
        icon={<BraiddLogoWhite />}
        boxSize="2rem"
        mb="2.5rem"
        variant="unstyled"
        _focus={{}}
        _hover={{}}
        onClick={(() => { navigate('/') })}
      />

      <CategoryText>PAGES</CategoryText>
      <NavbarEntry icon={RiDashboardLine} route='/dashboard'>
          Dashboard
      </NavbarEntry>
      <NavbarEntry icon={FaTelegram} route='/add'>
          Add Channel
      </NavbarEntry>
      <CategoryText>MORE</CategoryText>
      <NavbarEntry icon={IoIosHelpCircle} route="/contact">
          Contact Us
      </NavbarEntry>
    </Box>
  )
}
