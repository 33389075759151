import { Box, Text, Link as ChakraLink, OrderedList, ListItem } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { useContext, useEffect } from 'react'

import { QRCode } from 'react-qrcode-logo'
import { AppContext } from '../AppContext'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { BackToDashboard } from './components/BackToDashboard'
import { getNonce } from '../utils/utils'

export const AddChannel = () => {
  const botName = process.env.REACT_APP_BOTNAME || ''

  const { accessToken, nonce, setNonce } = useContext(AppContext)

  useEffect(() => {
    getNonce(accessToken).then((nonce) => { if (setNonce) setNonce(nonce) })
    setInterval(() => {
      getNonce(accessToken).then((nonce) => { if (setNonce) setNonce(nonce) })
    }, 150000) // Refresh every 2.5 mins because nonce has 5 min validity
  }, [])

  const deepLink = `https://t.me/${botName}?startgroup=${nonce}`

  return (
    <Box mx="auto" width={{ base: 'none', lg: 'max-content' }} maxWidth={{ base: '30rem', lg: '60rem' }} px={{ base: '1rem', lg: '8rem' }}>
      <BackToDashboard />
      <Text
        fontFamily="inter"
        fontWeight="700"
        color="#1E293B"
        fontSize={{ base: '1.5rem', lg: '1.875rem' }}>Connecting your Telegram Channel is easy! ✨</Text>
      <Text
        fontFamily="inter"
        fontWeight="400"
        color="#475569"
      >Just follow a few easy steps below. You need to have your phone ready with the telegram app installed, or use the telegram desktop app.</Text>

      <Text mt="2rem" mb="0.25rem" fontFamily="inter" fontWeight="600" color="#1E293B">
        Instructions
      </Text>
      <OrderedList pl="1rem" fontFamily="inter" fontWeight="400" color="#475569" fontSize="0.875rem" mb="0.5rem" spacing="0.25rem">
        <ListItem>
          Scan the QR code below to open your telegram app on your mobile device. Or, if you are accessing this page on your mobile device, click on &nbsp;
          <ChakraLink mt="1rem" color="#383ced" href={deepLink} isExternal>Open in Telegram <ExternalLinkIcon mx='2px' /></ChakraLink>.
        </ListItem>
        <ListItem>
          Choose your Telegram group that you want to add Braidd to.
        </ListItem>
        <ListItem>
          Grant admin rights to Braidd, so that Braidd can help you manage your group. Not sure how to grant admin rights? Read this <ChakraLink color="#383ced" as={Link} to="/add-admin-rights">
            guide</ChakraLink>.
        </ListItem>
      </OrderedList>
      <ChakraLink
        as={Link}
        color="#383ced"
        to="/add-alternate"
        fontFamily="inter"
        fontWeight="700"
      >
        FAQ: Problems adding Braidd?
      </ChakraLink>
      <Box display="flex" flexDir="column" alignItems="center" mt="0.5rem" mb="2rem">
        <QRCode
          value={deepLink}
          ecLevel='H'
          qrStyle='dots'
          fgColor="#6366F1"
          logoImage="./logo192.png"
          size={200}
          logoOpacity={1}
          logoWidth={50}
          logoHeight={50}
          removeQrCodeBehindLogo={true}
          eyeRadius={3} />

      </Box>
    </Box>
  )
}
