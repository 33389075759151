import { Box, Button, Text, List, ListItem, useBreakpointValue } from '@chakra-ui/react'
import { FaPlus } from 'react-icons/fa'
import { useContext } from 'react'
import { DashboardChannelCard } from './components/DashboardChannelCard'
import { DashboardWelcome } from './components/DashboardWelcome'
import { NoChannels } from './components/NoChannels'
import { AppContext } from '../AppContext'
import { Link } from 'react-router-dom'

export const Dashboard = () => {
  const { channels } = useContext(AppContext)

  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const cards = channels
    .sort((channel1, channel2) => channel1.TelegramGroup.telegramChatTitle > channel2.TelegramGroup.telegramChatTitle ? 1 : -1)
    .map((channel) => {
      const { id, telegramChatTitle } = channel.TelegramGroup
      return (
        <ListItem key={id} >
          <DashboardChannelCard channelId={id} channelTitle={telegramChatTitle}/>
        </ListItem>
      )
    })

  const hasChannels = Array.isArray(channels) && channels.length > 0

  return (
    <Box>

      <Box display="flex" flexDirection="column" p="2rem">
        <Box {...(isDesktop ? {} : { display: 'none' })}>
          <DashboardWelcome />
          <Box bgColor="#E2E8F0" height="0.125rem" mt="2rem" maxWidth="60rem" width="100%" mx="auto"/>
        </Box>
        <Box px="1rem" width="min-content" mx="auto">
          <Box
            display="flex"
            alignItems="center"
            width={{ md: '30rem', lg: '48rem' }}
            justifyContent={isDesktop ? 'space-between' : 'center'}
          >
            <Text
              fontFamily="inter"
              fontWeight="700"
              fontSize="1.25rem"
              pt="1rem"
              color="#1E293B"
              {...(isDesktop ? { pb: '1.5rem' } : {})}
            > Telegram Channels
            </Text>
            <Box {...(isDesktop ? {} : { display: 'none' })}>
              <Link to='/add'>
                <Button
                  height="2.375rem"
                  variant="solid"
                  bgColor="#6366F1"
                  color="white"
                  mb="0.5rem"
                  _hover={{
                    bgColor: '#383ced'
                  }}
                  _focus={{ boxShadow: '0 0 0 0.125rem #C7D2FE' }}
                  _active={{
                    bgColor: '#383ced'
                  }}
                  fontFamily="inter"
                  fontWeight="500"
                  fontSize="0.875rem"
                  leftIcon={<FaPlus color='#C7D2FE' />}
                >
              Add Channel
                </Button>
              </Link>
            </Box>
          </Box>
          <Text
            fontFamily="inter"
            fontWeight="500"
            fontSize="1rem"
            pb="1.5rem"
            color="#1E293B"
            {...(hasChannels ? {} : { display: 'none' })}
          > Current Connections 🔗
          </Text>
          {hasChannels
            ? (<List style={{ listStyleType: 'none', padding: 0 }}>{cards}</List>)
            : <NoChannels />}
          <Box {...(isDesktop ? { display: 'none' } : {})}>
            <Link to='/add'>
              <Button
                height="2.375rem"
                variant="solid"
                bgColor="#6366F1"
                color="white"
                isFullWidth
                mb="0.5rem"
                _hover={{
                  bgColor: '#383ced'
                }}
                _focus={{ boxShadow: '0 0 0 0.125rem #C7D2FE' }}
                _active={{
                  bgColor: '#383ced'
                }}
                fontFamily="inter"
                fontWeight="500"
                fontSize="0.875rem"
                leftIcon={<FaPlus color='#C7D2FE' />}
              >
              Add Channel
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>

  )
}
