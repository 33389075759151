import { Box, Text } from '@chakra-ui/react'

import { AppContext } from '../../AppContext'
import { useContext } from 'react'

export const DashboardWelcome = () => {
  const { userEmail: userName } = useContext(AppContext)

  return (
    <Box
      width="100%"
      maxWidth="60rem"
      mx="auto"
      bgColor="#C7D2FE"
      display="flex"
      flexDir="row"
      alignItems="center">
      <Box display="flex" flexDir="column" padding="1.5rem" >
        <Text
          fontFamily="roboto"
          fontWeight="700"
          fontSize="1.875rem"
          color="#1E293B">
          Good day, {userName}! 👋
        </Text>
        <Text fontFamily="roboto" fontSize="1rem" fontWeight="400" color="#475569">
        Here are your telegram channels:
        </Text>
      </Box>
      <DashboardWelcomeIllustration
        viewBox="120 60 220 40"
        preserveAspectRatio="xMidYMid meet"
        height="7rem"
        width="7rem"
      />
    </Box>
  )
}

const DashboardWelcomeIllustration = (props: any) => (
  <svg
    width={348}
    height={116}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m143.525 69.523-86.99 113.634-10.08-66.29L.423 68.112l143.102 1.41Z"
      fill="url(#a)"
    />
    <mask
      id="b"
      style={{
        maskType: 'alpha'
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={68}
      width={144}
      height={116}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m143.525 69.523-86.99 113.634-10.08-66.29L.423 68.112l143.102 1.41Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#b)">
      <path
        transform="rotate(64 165.094 59.002)"
        fill="url(#c)"
        d="M165.094 59.002h80v152h-80z"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m140.915-13.173 87.344 19.26-34.887 23.22-15.458 38.952-36.999-81.432Z"
      fill="url(#d)"
    />
    <mask
      id="e"
      style={{
        maskType: 'alpha'
      }}
      maskUnits="userSpaceOnUse"
      x={140}
      y={-14}
      width={89}
      height={83}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m140.915-13.173 87.344 19.26-34.887 23.22-15.458 38.952-36.999-81.432Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#e)">
      <path
        transform="rotate(-51 129.352 -22.964)"
        fill="url(#f)"
        d="M129.352-22.964h50v95h-50z"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M318.789 81.226 291.99 166.56l-20.09-36.778-37.457-18.795 84.346-29.76Z"
      fill="url(#g)"
    />
    <mask
      id="h"
      style={{
        maskType: 'alpha'
      }}
      maskUnits="userSpaceOnUse"
      x={234}
      y={81}
      width={85}
      height={86}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M318.789 81.226 291.99 166.56l-20.09-36.778-37.457-18.795 84.346-29.76Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#h)">
      <path
        transform="rotate(44 329.555 70.562)"
        fill="url(#i)"
        d="M329.555 70.562h50v95h-50z"
      />
    </g>
    <defs>
      <linearGradient
        id="a"
        x1={115.469}
        y1={12}
        x2={0.423}
        y2={68.112}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A5B4FC" />
        <stop offset={1} stopColor="#818CF8" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={174.909}
        y1={96.299}
        x2={174.909}
        y2={211.002}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4338CA" />
        <stop offset={1} stopColor="#6366F1" stopOpacity={0.01} />
      </linearGradient>
      <linearGradient
        id="d"
        x1={115.742}
        y1={17.913}
        x2={177.914}
        y2={68.259}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A5B4FC" />
        <stop offset={1} stopColor="#818CF8" />
      </linearGradient>
      <linearGradient
        id="f"
        x1={135.486}
        y1={0.346}
        x2={135.486}
        y2={72.036}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4338CA" />
        <stop offset={1} stopColor="#6366F1" stopOpacity={0.01} />
      </linearGradient>
      <linearGradient
        id="g"
        x1={290.016}
        y1={53.44}
        x2={234.443}
        y2={110.987}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A5B4FC" />
        <stop offset={1} stopColor="#818CF8" />
      </linearGradient>
      <linearGradient
        id="i"
        x1={335.689}
        y1={93.873}
        x2={335.689}
        y2={165.562}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4338CA" />
        <stop offset={1} stopColor="#6366F1" stopOpacity={0.01} />
      </linearGradient>
    </defs>
  </svg>
)
