import axios from 'axios'
import { Self, TelegramChannel } from '../types/ChannelTypes'

// Utility function to call fetch with access token
export const fetchWithToken = ({ url, accessToken }: {url: string, accessToken: string}) => {
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  }).then(({ data }) => data)
}

// Utility function to return telegram channels which
// user has permissions for

export const getTelegramChannels = async ({ accessToken }: { accessToken: string }) => {
  const braiddAPI = process.env.REACT_APP_API_URL || ''
  const getTelegramChannelsUrl = `${braiddAPI}/channel/`
  const telegramChannelsResponse = await fetchWithToken({ url: getTelegramChannelsUrl, accessToken })
  const telegramChannels = await telegramChannelsResponse

  return telegramChannels
}

// Utility function to update telegram channel settings

export const putTelegramChannel = async ({ accessToken, channelId, config, body }: { accessToken: string, channelId: string, config: string, body: string }) => {
  const braiddAPI = process.env.REACT_APP_API_URL || ''
  const putTelegramChannelsUrl = `${braiddAPI}/channel/${channelId}/${config}`
  const telegramChannelsResponse = await fetch(putTelegramChannelsUrl, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    },
    body
  }
  )
  const telegramChannelsResponseJson = await telegramChannelsResponse.json()

  return telegramChannelsResponseJson
}

const braiddAPI = process.env.REACT_APP_API_URL || ''

export const getNonce = async (accessToken: string): Promise<string> => {
  const deepLink = await fetchWithToken({ url: `${braiddAPI}/nonce`, accessToken })
  return deepLink.value
}

export const getChannels = async (accessToken: string): Promise<TelegramChannel[]> => {
  const channels = await fetchWithToken({ url: `${braiddAPI}/channel/`, accessToken })
  return channels
}

export const getSelf = async (accessToken: string): Promise<Self> => {
  const self = await fetchWithToken({ url: `${braiddAPI}/user/self`, accessToken })
  return self
}
