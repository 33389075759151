import { Text, TextProps } from '@chakra-ui/react'

export const CategoryText = (props: TextProps) => {
  const { children } = props
  return (

    <Text
      color="#64748B"
      fontWeight="500"
      fontSize="0.75rem"
      fontStyle="normal"
      mt="2rem"
      mb="0.5rem"
      {...props}
    >
      {children}
    </Text>
  )
}
