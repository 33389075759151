import { Text, Icon, Link as ChakraLink } from '@chakra-ui/react'
import { FaChevronLeft } from 'react-icons/fa'
import { Link } from 'react-router-dom'

export const BackToDashboard = () => {
  return (
    <ChakraLink
      as={Link}
      to='/dashboard'
      color="#6366F1"
      fontFamily="inter"
      fontWeight="500"
      display="flex"
      alignItems="center"
      mt="2.375rem"
      mb="1rem"
      _focus={{
        boxShadow: '0 0 0 0.0625rem #6366F1'
      }}>
      <Icon as={FaChevronLeft} color='#818CF8'/><Text ml="0.5rem">Back to Dashboard</Text>
    </ChakraLink>)
}
