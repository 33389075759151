import { Box, Text, Link as ChakraLink } from '@chakra-ui/react'

import { BackToDashboard } from './components/BackToDashboard'
import { ExternalLinkIcon } from '@chakra-ui/icons'

export const ContactUs = () => {
  return (
    <Box mx="auto" minWidth={{ lg: '50rem' }} maxWidth={{ base: '30rem', lg: '60rem' }} px={{ base: '1rem' }}>
      <BackToDashboard />
      <Text
        fontFamily="inter"
        fontWeight="700"
        color="#1E293B"
        fontSize={{ base: '1.5rem', lg: '1.875rem' }}>Contact Us! 📨</Text>

      <Text
        fontFamily="inter"
        fontWeight="400"
        color="#475569"
      >
      Join our Braidd Users telegram channel <ChakraLink mt="1rem" color="#383ced" href={'https://t.me/BraiddUsers'} isExternal>here<ExternalLinkIcon mx='2px' /></ChakraLink>.
      You can also reach us using our <ChakraLink mt="1rem" color="#383ced" href={'https://braidd.io/contact-us'} isExternal>contact form<ExternalLinkIcon mx='2px' /></ChakraLink>.
      </Text>
    </Box>
  )
}
