import { Box, Icon, Text, Button } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { IconType } from 'react-icons'
import { useLocation, useNavigate } from 'react-router-dom'

export const NavbarEntry = ({ icon, route, children }: {icon: IconType, route: string, children?: string}) => {
  const [isSelected, setIsSelected] = useState(false)
  const navigate = useNavigate()
  const path = useLocation().pathname
  useEffect(() => {
    if (path.startsWith(route)) {
      setIsSelected(true)
    } else {
      setIsSelected(false)
    }
  }, [navigate])

  return (<Box
    as={Button}
    borderRadius="0"
    width="100%"
    justifyContent="left"
    bgColor={isSelected ? '#0F172A' : 'transparent'}
    _hover={{
      bgColor: '#0F172A'
    }}
    _focus={{
      boxShadow: '0 0 0 0.125rem #6366F1'
    }}
    onClick={() => { navigate(route) }}
    display="flex"
    alignItems="center"
    p="0.5rem"
    my="0.5rem">
    <Icon boxSize="1.5rem" as={icon} mr="0.75rem" color={isSelected ? '#6366F1' : '#64748B' } />
    <Text color="gray.100" fontSize="0.875rem" fontWeight="400">{children}</Text>
  </Box>)
}
