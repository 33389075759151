import { AppContext } from '../AppContext'
import { useContext } from 'react'

import { Box, Text, Button, useToast } from '@chakra-ui/react'

export const ViewToken = () => {
  const { accessToken } = useContext(AppContext)
  const toast = useToast()
  return (
    <Box mt="50px" alignItems="center" display="flex" flexDirection="column">

      <Button
        height="2.375rem"
        variant="solid"
        bgColor="#6366F1"
        color="white"
        mb="0.5rem"
        _hover={{
          bgColor: '#383ced'
        }}
        _focus={{ boxShadow: '0 0 0 0.125rem #C7D2FE' }}
        _active={{
          bgColor: '#383ced'
        }}
        fontFamily="inter"
        fontWeight="500"
        fontSize="0.875rem"
        onClick={() => {
          navigator.clipboard.writeText(accessToken)
          toast({
            title: 'Copied to clipboard',
            status: 'success',
            duration: 1000,
            isClosable: true
          })
        }}
      >
        Copy Token to Clipboard
      </ Button>
      <Text
        width="400px"
        border="black 1px solid"
        sx={{
          overflowWrap: 'break-word',
          mt: '10px',
          padding: '10px'
        }} >
        {accessToken}
      </Text>
    </Box>

  )
}
