import { Box, Text, Link as ChakraLink, OrderedList, ListItem } from '@chakra-ui/react'
import { useContext, useEffect } from 'react'

import { QRCode } from 'react-qrcode-logo'
import { AppContext } from '../AppContext'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { BackToDashboard } from './components/BackToDashboard'
import { getNonce } from '../utils/utils'

export const AddChannelAlternate = () => {
  const botName = process.env.REACT_APP_BOTNAME || ''

  const { accessToken, nonce, setNonce } = useContext(AppContext)

  useEffect(() => {
    getNonce(accessToken).then((nonce) => { if (setNonce) setNonce(nonce) })
    setInterval(() => {
      getNonce(accessToken).then((nonce) => { if (setNonce) setNonce(nonce) })
    }, 150000) // Refresh every 2.5 mins because nonce has 5 min validity
  }, [])

  const deepLinkManual = `https://t.me/${botName}?start=${nonce}`

  return (
    <Box mx="auto" width={{ base: 'none', lg: 'max-content' }} maxWidth={{ base: '30rem', lg: '60rem' }} px={{ base: '1rem', lg: '8rem' }}>
      <BackToDashboard />
      <Text
        fontFamily="inter"
        fontWeight="700"
        color="#1E293B"
        fontSize={{ base: '1.5rem', lg: '1.875rem' }}>
            Connect Telegram Channel (Manual) 🛠️ </Text>
      <Text
        fontFamily="inter"
        fontWeight="400"
        color="#475569"
      >By default, Braidd uses <ChakraLink
          color="#383ced"
          href="https://core.telegram.org/bots#deep-linking"
          isExternal>
              deep linking
        </ChakraLink> to connect groups, in order to achieve a seamless user experience. This is not supported on some versions of the telegram app. In such cases, you&apos;ll need to manually connect Braidd to your group. </Text>

      <Text mt="2rem" mb="0.25rem" fontFamily="inter" fontWeight="600" color="#1E293B">
        Instructions (Manual)
      </Text>
      <OrderedList pl="1rem" fontFamily="inter" fontWeight="400" color="#475569" fontSize="0.875rem" mb="0.5rem" spacing="0.25rem">
        <ListItem>
          Link your telegram account to Braidd by scanning the QR code below. Or, if you are accessing this page on your mobile device, click on &nbsp;
          <ChakraLink mt="1rem" color="#383ced" href={deepLinkManual} isExternal>Open in Telegram <ExternalLinkIcon mx='2px' /></ChakraLink>.
        </ListItem>
        <ListItem>
          In your app, you should see a message from Braidd saying &quot;Account successfully linked&quot;.
        </ListItem>
        <ListItem>
            Open your telegram group and manually add @{botName}. Make sure you add the correct bot!
        </ListItem>
        <ListItem>
          Grant admin rights to Braidd, so that Braidd can help you manage your group.
        </ListItem>
        <ListItem>In the group, type the command <Text as="mark" color="#383ced">/start</Text>
        </ListItem>
        <ListItem>You should see a message informing you that your group has been successfully linked.</ListItem>
      </OrderedList>
      <Box display="flex" flexDir="column" alignItems="center" mt="0.5rem" mb="2rem">
        <QRCode
          value={deepLinkManual}
          ecLevel='H'
          qrStyle='dots'
          fgColor="#6366F1"
          logoImage="./logo192.png"
          logoOpacity={1}
          size={200}
          logoWidth={50}
          logoHeight={50}
          removeQrCodeBehindLogo={true}
          eyeRadius={3} />

      </Box>
    </Box>
  )
}
