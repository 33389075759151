import { Typography, CircularProgress, Box } from '@mui/material'

type LoadingProps = {
  loadingText: string
}

export const Loading = (props: LoadingProps) => {
  const { loadingText } = props

  return (
    <>
      <Box display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh">
        <CircularProgress/>
        <Typography marginTop="1rem">{loadingText}</Typography>
      </Box>
    </>)
}
