import React, { createContext } from 'react'
import { TelegramChannel } from './types/ChannelTypes'

// Allow access token and braidd state to be used throughout app without prop drilling
export const AppContext = createContext<{
  accessToken:string,
  channels: TelegramChannel[],
  nonce: string,
  userEmail: string
  setChannels?: React.Dispatch<React.SetStateAction<TelegramChannel[]>>,
  setNonce?: React.Dispatch<React.SetStateAction<string>>,
}>({
  accessToken: '',
  channels: [],
  nonce: '',
  userEmail: '',
  setChannels: undefined,
  setNonce: undefined
})
