export const BraiddLogoWhite = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 29.736 11"
    height="11pt"
    width={39.648}
    transform="scale(2.5) translate(10)"
  >
    <defs>
      <symbol id="a" overflow="visible">
        <path
          d="M2.531-9.031c.04 0 .082.008.125.015.676.043 1.016.434 1.016 1.172 0 .805-.344 1.7-1.031 2.688A8.666 8.666 0 0 1 1.25-3.61c.031.25.066.484.11.703.28 1.324.773 1.984 1.484 1.984.226 0 .445-.05.656-.156.469-.25.852-.727 1.156-1.438-.18-.07-.34-.164-.484-.28-.399-.313-.594-.704-.594-1.173 0-.258.094-.484.281-.672a.98.98 0 0 1 .735-.328c.281 0 .523.086.734.25.27.211.406.508.406.89 0 .22-.027.466-.078.735.489-.02.899-.148 1.235-.39a.311.311 0 0 1-.063-.032c-.418-.226-.625-.593-.625-1.093 0-.164.04-.329.125-.485.176-.289.453-.437.828-.437a.87.87 0 0 1 .578.203c.258.21.391.523.391.937 0 .043-.008.11-.016.204.094-.04.223-.125.391-.25.219-.145.375-.22.469-.22.113 0 .222.032.328.095.113.074.172.148.172.218 0 .032-.012.094-.031.188-.211.699-.313 1.297-.313 1.797 0 .418.047.75.14 1 .114.28.27.421.47.421.113-.019.304-.109.578-.265.25-.156.43-.234.546-.234.051 0 .11.015.172.046.102.075.156.168.156.282-.011.21-.164.43-.453.656-.355.25-.73.375-1.125.375-.125 0-.246-.016-.359-.047-.637-.176-.953-.863-.953-2.063 0-.414.035-.851.11-1.312-.18.086-.384.14-.61.172a1.511 1.511 0 0 1-.328.344c-.524.43-1.2.64-2.032.64a4.51 4.51 0 0 1-.671 1.25C4.234-.406 3.617-.062 2.922-.062c-.969 0-1.68-.602-2.125-1.813C.484-2.707.328-3.703.328-4.859c0-.063.004-.145.016-.25.05-.813.203-1.563.453-2.25.414-1.114.992-1.672 1.734-1.672Zm-1.25 3.437a5.43 5.43 0 0 0-.078.907c.238-.25.484-.547.734-.891.625-.863.938-1.594.938-2.188a.277.277 0 0 0-.031-.109c-.063-.176-.168-.266-.313-.266-.312 0-.574.211-.781.625-.2.399-.355 1.04-.469 1.922ZM4.688-4.14a.644.644 0 0 0-.125.016c-.125.055-.188.137-.188.25 0 .031.004.063.016.094.039.23.203.422.484.578.05-.227.078-.422.078-.578 0-.239-.09-.36-.266-.36Zm2.234-.437c0 .23.14.39.422.484a.874.874 0 0 0 .062-.312c0-.051-.008-.102-.015-.157-.043-.164-.133-.25-.266-.25-.012 0-.027.008-.047.016-.105.031-.156.106-.156.219Zm0 0"
          style={{
            stroke: 'none'
          }}
        />
      </symbol>
      <symbol id="b" overflow="visible">
        <path
          d="M.203-1.844c0-.133.004-.273.016-.422a4.118 4.118 0 0 1 .64-1.78C1.273-4.68 1.77-5 2.344-5c.25 0 .469.047.656.14A.414.414 0 0 1 3.328-5c.082 0 .129.008.14.016.177.054.266.218.266.5 0 .117-.023.359-.062.734-.125 1.105-.188 1.793-.188 2.063 0 .187.036.359.11.515.113.23.258.344.437.344a.408.408 0 0 0 .11-.016c.113-.02.28-.11.5-.265.218-.157.39-.235.515-.235.051 0 .11.016.172.047a.396.396 0 0 1 .156.313c-.011.21-.164.421-.453.64-.355.25-.734.375-1.14.375-.493 0-.852-.25-1.079-.75-.062.074-.124.14-.187.203-.387.368-.781.547-1.188.547-.25 0-.464-.066-.64-.203C.398-.453.203-1.008.203-1.844Zm2.578-1.922s.008-.132.031-.406a.63.63 0 0 0-.265-.062.822.822 0 0 0-.219.03c-.367.106-.672.446-.922 1.016a3.874 3.874 0 0 0-.328 1.532v.062c.008.48.129.719.36.719.125-.008.273-.078.453-.203.175-.125.304-.242.39-.36.051-.07.117-.289.203-.656a12.87 12.87 0 0 0 .297-1.672Zm0 0"
          style={{
            stroke: 'none'
          }}
        />
      </symbol>
      <symbol id="c" overflow="visible">
        <path
          d="M1.39-4.547c0 .117-.015.36-.046.734-.125 1.118-.188 1.81-.188 2.079 0 .18.035.343.11.5.113.23.257.343.437.343h.125c.113-.03.281-.125.5-.28.207-.157.375-.235.5-.235.05 0 .11.023.172.062a.37.37 0 0 1 .156.313c-.011.199-.164.406-.453.625-.367.25-.746.375-1.14.375-.336 0-.61-.114-.829-.344C.41-.727.25-1.285.25-2.047c0-.258.05-.773.156-1.547.04-.351.07-.613.094-.781a.433.433 0 0 0 .016-.11c.062-.382.222-.577.484-.577.04 0 .082.007.125.015.176.043.266.211.266.5ZM.735-6.078c0-.07.016-.14.047-.203.07-.145.196-.219.375-.219.07 0 .14.016.203.047.145.074.22.2.22.375 0 .074-.016.14-.048.203-.074.148-.199.219-.375.219a.444.444 0 0 1-.203-.047c-.148-.07-.219-.195-.219-.375Zm0 0"
          style={{
            stroke: 'none'
          }}
        />
      </symbol>
      <symbol id="d" overflow="visible">
        <path
          d="M.281-1.86c0-.132.008-.273.031-.421.083-.664.29-1.258.625-1.781.415-.633.915-.954 1.5-.954.208 0 .407.04.594.11L3.5-8.281c.063-.457.227-.692.5-.703.04 0 .082.007.125.015.176.043.266.215.266.516 0 .117-.086.805-.25 2.062-.375 2.856-.563 4.422-.563 4.704 0 .187.035.359.11.515.101.23.25.344.437.344a.408.408 0 0 0 .11-.016c.113-.02.285-.11.515-.265.219-.157.39-.235.516-.235.05 0 .101.024.156.063.094.074.14.172.14.297-.023.199-.171.414-.453.64-.355.25-.73.375-1.125.375-.492 0-.859-.242-1.109-.734a1.986 1.986 0 0 1-.203.203c-.375.344-.758.516-1.14.516-.243 0-.454-.063-.641-.188C.484-.453.28-1.016.28-1.859Zm2.094.391c.05-.07.113-.265.188-.578.156-.676.273-1.383.359-2.125a1.083 1.083 0 0 0-.281-.078.822.822 0 0 0-.22.031c-.366.094-.671.43-.921 1a3.905 3.905 0 0 0-.328 1.547v.125c.02.438.14.656.36.656.113-.007.257-.07.437-.187.176-.133.312-.266.406-.39Zm0 0"
          style={{
            stroke: 'none'
          }}
        />
      </symbol>
    </defs>
    <g
      style={{
        fill: '#fff',
        fillOpacity: 1
      }}
    >
      <use
        style={{
          fill: '#fff',
          fillOpacity: 1
        }}
        y={9.788}
        x={0.737}
        xlinkHref="#a"
      />
      <use
        style={{
          fill: '#fff',
          fillOpacity: 1
        }}
        y={9.788}
        x={11.465}
        xlinkHref="#b"
      />
      <use
        style={{
          fill: '#fff',
          fillOpacity: 1
        }}
        y={9.788}
        x={16.416}
        xlinkHref="#c"
      />
      <use
        style={{
          fill: '#fff',
          fillOpacity: 1
        }}
        y={9.788}
        x={18.69}
        xlinkHref="#d"
      />
      <use
        style={{
          fill: '#fff',
          fillOpacity: 1
        }}
        y={9.788}
        x={23.242}
        xlinkHref="#d"
      />
    </g>
  </svg>
)
