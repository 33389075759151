import { Box, Icon, Text, LinkBox } from '@chakra-ui/react'
import { FaTelegram } from 'react-icons/fa'
import { Link } from 'react-router-dom'

type DashboardChannelCardProps = {
    isConnected?: boolean,
    channelTitle: string,
    channelId: string
}

export const DashboardChannelCard = (props: DashboardChannelCardProps) => {
  const { channelTitle, channelId } = props

  const channelIdRoute = `/channel/${channelId}`
  return (
    <LinkBox
      as={Link}
      to={channelIdRoute}
      height="5rem"
      width={{ md: '30rem', lg: '48rem' }}
      bgColor="white"
      display="flex"
      flexDir="row"
      boxShadow="0 0 0 0.0625rem #E2E8F0"
      borderRadius="0.25rem"
      alignItems="center"
      justifyContent="space-between"
      p="1.25rem"
      mb="0.5rem"
      _hover={{
        boxShadow: '0 0 0 0.0625rem #6366F1'
      }}
      _focus={{
        boxShadow: '0 0 0 0.0625rem #6366F1'
      }}
      _active={{
        boxShadow: '0 0 0 0.125rem #6366F1'
      }}
    >
      <Box
        display="flex"
        flexDir="row"
        alignItems="center"
        my="1rem"
        mr="2rem"
      >
        <Icon as={FaTelegram} boxSize="2.25rem" color="#229ED9" mr="1rem"/>
        <Text
          fontFamily="inter"
          fontWeight="600"
          fontSize="1rem"
          color="#1E293B">
          {channelTitle}
        </Text>
      </Box>
      <Box bgColor="#D1FAE5"
        borderRadius="0.9375rem"
        py="0.125rem"
        px="0.625rem"
      >
        <Text
          fontFamily="inter"
          fontWeight="500"
          fontSize="0.875rem"
          color="#059669">Connected</Text>
      </Box>
    </LinkBox>)
}
