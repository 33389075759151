import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons'
import { Box, Text, Avatar, Button, Icon, Menu, MenuButton, MenuDivider, MenuItem, MenuList } from '@chakra-ui/react'
import { FcBusinessman } from 'react-icons/fc'
import { useContext } from 'react'
import { AppContext } from '../../AppContext'
import { useAuth0 } from '@auth0/auth0-react'

const AvatarUsername = ({ userName }: {userName: string}) => {
  return (
    <Box display="flex" alignItems="center">
      <Avatar bg="#6366F1" size="sm" icon={<FcBusinessman fontSize="1.8rem"/>}
        mx = "0.75rem" />
      <Text color="#64748B"
        fontWeight="500"
        fontSize="0.875rem"
        fontStyle="normal">{userName}</Text>
    </Box>

  )
}

export const AvatarMenu = () => {
  const { logout } = useAuth0()
  const { userEmail: userName } = useContext(AppContext)
  const userNameTruncatedShort = userName.length > 12 ? `${userName.slice(0, 12)}...` : userName
  const userNameTruncatedLong = userName.length > 16 ? `${userName.slice(0, 16)}...` : userName

  return (
    <Menu matchWidth={true} autoSelect={false}>
      {({ isOpen }) => (
        <>
          <MenuButton
            role="group"
            isActive={isOpen}
            as={Button}
            bgColor="white"
            borderRadius="0.5rem"
            p="0"
            pr="0.5rem"
            ml="0.5rem"
            height="3rem"
            width="12rem"
            _focus={{}}
            _groupFocus={{
              bgColor: 'lightgray.100'
            }}
            _groupActive={{
              bgColor: 'lightgray.100'
            }}
            rightIcon={<Icon color="#64748B" boxSize="1.5rem" as={isOpen ? ChevronUpIcon : ChevronDownIcon} />}
          >
            <AvatarUsername userName={userNameTruncatedShort}/>
          </MenuButton>
          <MenuList minWidth="100%" >
            <Box display="flex" flexDir="column" px="0.8rem">
              <Text
                color="#0F172A"
                fontWeight="500"
                fontSize="1rem"
                fontStyle="normal">{userNameTruncatedLong}
              </Text>
              <Text
                color="#64748B"
                fontWeight="400"
                fontSize="1rem"
                fontStyle="italic">Administrator
              </Text>
            </Box>
            <MenuDivider />
            <MenuItem onClick={() => logout()} >
              <Text
                color="#6366F1"
                fontWeight="500"
                fontSize="1rem"
                fontStyle="normal">Sign Out</Text></MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  )
}
