import {
  Box,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  useBreakpointValue
} from '@chakra-ui/react'

import { AvatarMenu } from './components/AvatarMenu'
import { useLocation, Routes, Route } from 'react-router-dom'
import { Dashboard } from './Dashboard'
import { ViewToken } from './ViewToken'
import { AddChannel } from './AddChannel'
import { AddChannelAlternate } from './AddChannelAlternate'
import { ConfigureChannel } from './ConfigureChannel'
import { ContactUs } from './ContactUs'
import { Sidebar } from './components/Sidebar'
import { useEffect, useRef } from 'react'
import { BiMenu } from 'react-icons/bi'
import { IconContext } from 'react-icons'
import { AddAdminRights } from './AddAdminRights'

export const DesktopView = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef(null)
  const location = useLocation()

  const isDesktop = useBreakpointValue({ base: false, lg: true })

  useEffect(() => onClose(), [location])

  return (
    <Box display="flex" flexDirection="row" height="100%">
      {/* Desktop Sidebar */}
      <Sidebar {...(isDesktop ? {} : { display: 'none' })}/>

      <Drawer
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <Sidebar width="100%" {...(isDesktop ? { display: 'none' } : {})}/>
        </DrawerContent>
      </Drawer>

      {/* Screen ex sidebar */}
      <Box
        display="flex"
        flexDirection="column"
        bgColor="#F2F5F9"
        minHeight="100vh"
        width="100%">
        {/* Topbar */}
        <Box display="flex"
          bgColor="white"
          height="4rem"
          width="100%"
          justifyContent="flex-end"
          alignItems="center"
          pr="2rem"
          pl="1rem">
          <Box flexGrow={1} {...(isDesktop ? { display: 'none' } : {})}>
            <IconContext.Provider
              value={{ color: '#64748B', size: '2rem' }}
            >
              <IconButton
                aria-label="menu"
                icon={<BiMenu />}
                boxSize="2.5rem"
                ml="1rem"
                variant="solid"
                _focus={{}}
                _hover={{}}
                onClick={onOpen}
                ref={btnRef}
              />
            </IconContext.Provider>
          </Box>
          <Box display="flex" alignItems="center">
            <Box width="0.0625rem" height="1.5rem" bgColor="#E2E8F0" />
            <AvatarMenu />
          </Box>
        </Box>
        {/* Main Area */}
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path='/token' element={<ViewToken />} />
          <Route path='/add' element={<AddChannel />} />
          <Route path='/contact' element={<ContactUs />} />
          <Route path='/add-alternate' element={<AddChannelAlternate />} />
          <Route path='/add-admin-rights' element={<AddAdminRights />} />
          <Route path='/channel/:channelId' element={<ConfigureChannel />} />
          <Route path="*" element={<Dashboard />} />
        </Routes>
      </Box>
    </Box >
  )
}
