import { Box, Icon, Button, FormControl, FormLabel, Text, Textarea, Switch, useToast } from '@chakra-ui/react'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AppContext } from '../AppContext'
import { TelegramChannel } from '../types/ChannelTypes'
import { putTelegramChannel } from '../utils/utils'

import * as yup from 'yup'
import { Field, Formik } from 'formik'
import { BackToDashboard } from './components/BackToDashboard'
import { FaTelegram } from 'react-icons/fa'

export const ConfigureChannel = () => {
  const navigate = useNavigate()
  const toast = useToast()

  const [isSaving, setIsSaving] = useState(false)
  const [currentChannel, setCurrentChannel] = useState<TelegramChannel>()
  const { channelId } = useParams()

  const { accessToken, channels } = useContext(AppContext)

  // On page load,
  // Check if the channelId specified in the param exists
  // If yes, set the currentChannel
  // If no, redirect to main page
  useEffect(() => {
    const filteredChannels = channels.filter((channel) => channel.TelegramGroup.id === channelId)
    if (filteredChannels.length === 0) {
      navigate('/')
    } else {
      setCurrentChannel(filteredChannels[0])
    }
  },
  [])

  const saveSettings = async (values: {
    greetingText: string,
    isContentFilterEnabled: boolean
  }) => {
    if (!channelId) { return }

    setIsSaving(true)

    const { greetingText, isContentFilterEnabled } = values

    const greetingBody = JSON.stringify({
      enabled: greetingText.length > 0,
      value: greetingText
    })

    const contentFilterBody = JSON.stringify({
      enabled: isContentFilterEnabled,
      custom: []
    })

    const putGreetingResponse = putTelegramChannel({
      accessToken,
      channelId,
      config: 'greeting',
      body: greetingBody
    })

    const putContentFilterResponse = putTelegramChannel({
      accessToken,
      channelId,
      config: 'filter',
      body: contentFilterBody
    })

    const result = await Promise.all([putGreetingResponse, putContentFilterResponse])

    setIsSaving(false)
    toast({
      title: 'Settings saved!',
      position: 'top',
      status: 'success',
      duration: 2000,
      isClosable: true
    })

    return result
  }

  // Form configuration
  const validationSchema = yup.object({
    greetingText: yup.string(),
    isContentFilterEnabled: yup.boolean()
  })

  const greetingText = currentChannel?.TelegramGroup.greeting?.value || ''
  const isContentFilterEnabled = currentChannel?.TelegramGroup.contentFilter?.enabled || false

  return (
    <Box mx="auto" width={{ base: 'none', lg: 'max-content' }} maxWidth={{ base: '30rem', lg: '60rem' }} px={{ base: '1rem', lg: '8rem' }}>
      <BackToDashboard />
      <Box display="flex" flexDir="row" alignItems="center">
        <Text
          fontFamily="inter"
          fontWeight="700"
          fontSize="1.5rem"
          color="#1E293B">
          {currentChannel?.TelegramGroup.telegramChatTitle}
        </Text>
        <Icon as={FaTelegram} boxSize="2.25rem" color="#229ED9" ml="1rem"/>

      </Box>
      <Text
        fontFamily="inter"
        fontWeight="400"
        color="#475569"
        mb="1.5rem"
      >Telegram Chat ID: {currentChannel?.TelegramGroup.telegramChatId}</Text>

      <Formik
        initialValues={{
          greetingText: greetingText,
          isContentFilterEnabled: isContentFilterEnabled
        }}
        enableReinitialize={true}

        validationSchema={validationSchema}
        onSubmit={(values) => {
          saveSettings(values)
        }}
      >
        {({ handleSubmit, handleChange, values }) => (

          <form onSubmit={handleSubmit}>

            <Box bgColor="white" border="solid 0.0625rem #E3E8F0" p="1.5rem">

              <Text
                fontFamily="inter"
                fontWeight="700"
                fontSize="1.25rem"
                color="#1E293B">
Greeting text
              </Text>
              <Text
                fontFamily="inter"
                fontWeight="400"
                color="#475569"
                mb="1.5rem"
              >Braidd will display this message each time a new user joins the group.</Text>
              <FormControl>
                <Field
                  as={Textarea}
                  id="greetingText"
                  name="greetingText"
                  placeholder='Hello!...'
                  multiline
                  rows={2}
                  fullWidth
                  value={values.greetingText}
                  onChange={handleChange}
                />
              </FormControl>

              <Text
                mt="1.5rem"
                fontFamily="inter"
                fontWeight="700"
                fontSize="1.25rem"
                color="#1E293B">
Content filter
              </Text>
              <Text
                fontFamily="inter"
                fontWeight="400"
                color="#475569"
                mb="1.5rem"
              >Braidd will automatically block undesirable words and phrases to keep your community safe.</Text>
              <FormControl>
                <Box display="flex">
                  <FormLabel htmlFor='isChecked'>Enable content filter:</FormLabel>
                  <Field
                    as={Switch}
                    value={values.isContentFilterEnabled}
                    isChecked={values.isContentFilterEnabled}
                    onChange={handleChange}
                    colorScheme="purple"
                    id="isContentFilterEnabled"
                  />
                </Box>
              </FormControl>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  type="submit"
                  height="2.375rem"
                  variant="solid"
                  bgColor="#6366F1"
                  color="white"
                  mb="0.5rem"
                  _hover={{
                    bgColor: '#383ced'
                  }}
                  _focus={{ boxShadow: '0 0 0 0.125rem #C7D2FE' }}
                  _active={{
                    bgColor: '#383ced'
                  }}
                  fontFamily="inter"
                  fontWeight="500"
                  fontSize="0.875rem"
                  {...(isSaving ? { disabled: true } : {})}
                >
                  {isSaving ? 'Saving...' : 'Save'}
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  )
}
